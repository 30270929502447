import React, { ComponentType } from 'react'
import Context, { AuthContext } from './context'
import hoistNonReactStatic from 'hoist-non-react-statics'

function getDisplayName<T>(WrappedComponent: React.ComponentType<T>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

/**
 * Mapper that transforms AuthContext into desired props
 * @param context - AuthContext's current value
 * @returns props as object
 */
export type MapAuthToProps<Return> = (context: AuthContext) => Return

const defaultAuthToProps: MapAuthToProps<{ auth: AuthContext }> = (
  context: AuthContext
) => ({
  auth: context,
})

/**
 * Higher-order components that maps AuthContext to the props of another component
 * based on the provided `mapAuthToProps` and `Component`
 *
 * @param mapAuthToProps - a function that takes in the AuthContext and returns an object mapping that will translate directly to props
 * @returns a Component factory that returns the a given `Component` with additional props defined by `mapAuthToProps`
 * @example
 *    class Greeting extends React.Component {
 *      render() {
 *        const { auth } = this.props
 *        return <h1>Welcome, {auth.session.userInfo?.firstName}!</h1>
 *      }
 *    }
 *    export default withAuth()(Greeting)
 */
const withAuth =
  <MappedProps extends {} = { auth: AuthContext }>( // MappedProps generic type is the Result of given mapAuthToProps, default to output of defaultAuthToProps
    mapAuthToProps?: MapAuthToProps<MappedProps>
  ) =>
  <Props extends MappedProps>( // Props generic type is prop types for given Component, it should extend MappedProps or at least contains MappedProps
    Component: React.ComponentType<Props>
  ): // We are returning a component that has a prop definition that is the same as the provided Component sans MappedProps
  ComponentType<Omit<Props, keyof MappedProps>> => {
    class WithAuth extends React.Component<Omit<Props, keyof MappedProps>> {
      public static displayName = `WithAuth(${getDisplayName(Component)})`
      render() {
        return (
          <Context.Consumer>
            {(context) => {
              const mappedProps = mapAuthToProps
                ? mapAuthToProps(context)
                : defaultAuthToProps(context)
              return (
                <Component
                  {...(this.props as Props)} // pass-through Props
                  {...(mappedProps as MappedProps)} // given MappedProps
                />
              )
            }}
          </Context.Consumer>
        )
      }
    }
    return hoistNonReactStatic(WithAuth, Component)
  }

export default withAuth
